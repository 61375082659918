<template>
    <div
        class="confirm-modal"
    >
        <div class="confirm-modal__dialog confirm-modal__dialog-revision">
            <header class="confirm-modal__header">
                <h2>Revision Request</h2>
                <p class="modal__header-description modal__header-description--subtitle">
                    Project title
                </p>
                <p class="modal__header-description">
                    Order ID: {{ order.orderid }}
                </p>
                <p class="modal__header-description">
                    Title: «{{ order.title }}»
                </p>
                <p class="modal__header-description">
                    ID: {{ order.writer.sw_id }}
                </p>
                <p class="modal__header-description">
                    Nickname: {{ order.writer.nickname }}
                </p>
                <span
                    class="confirm-modal__close"
                    @click="close()"
                ><CloseIcon /></span>
            </header>
            <section class="confirm-modal__content">
                <form-textarea
                    v-model="$v.message.$model"
                    legend="Revision request"
                    type="text"
                    name="Revision request"
                    :field.sync="message"
                    :errors="validationMessage($v.message)"
                    @input.native="$v.message.$touch();"
                    @blur.native="$v.message.$touch();"
                />
            </section>
            <footer>
                <div class="confirm-modal__one-button-block">
                    <custom-button
                        :loading="dialog"
                        class="confirm-modal__one-button confirm-modal__one-button--main"
                        @on-btn-click="validate(false)"
                    >
                        Submit for revision
                    </custom-button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import CloseIcon from 'mdi-vue/Close.vue';
import formValidationMixin from '@/mixins/form-validation-mixin'

import { validationRules } from '@/validation/revision/rules'
import { formMessages } from '@/validation/revision/messages'

export default {
    name: 'ReportModal',
    components: {
        CloseIcon
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            dialog: false,
            message: ''
        }
    },
    methods: {
        close() {
            this.$emit('closeModal')
        },
        validationMessage: validationMessage(formMessages),
        async submit() {
            this.dialog = false
            this.$emit('confirmModal', {
                orderid: this.order.orderid,
                message: this.message
            })
            this.dialog = true
        }
    }
}
</script>

<style lang="scss" scoped>
    .confirm-modal{
        &__header{
            padding: 30px 50px 20px 30px;
        }
    }

    .modal__header-description {
        font-size: 13px;
    }

    .modal__header-description--subtitle{
        font-size: 16px;
        font-weight: bold;
        text-decoration: underline;
    }

    .confirm-modal__close{
        top: 22%
    }

    .confirm-modal__dialog,
    .confirm-modal__dialog-revision{
        max-width: 700px;
    }
    div .confirm-modal__one-button {
        width: 40%;
    }

</style>
