<template>
    <div
        class="order-card_body"
        :class="{'order-card_body__details': $route.name.includes('order_')}"
    >
        <div class="order-card_body__row">
            <div class="order-card_body__inf">
                <OrderCardBodyAdditionalInfo
                    v-for="info in additionalInfo"
                    :key="info.title"
                    :title="info.title"
                    :value="info.value"
                    :icon="info.icon"
                />
            </div>
        </div>
    </div>
</template>

<script>

import OrderCardBodyAdditionalInfo from '@/components/account/cards/order-card/elements/OrderCardBodyAdditionalInfo.vue';

export default {
    name: 'OrderCardBody',
    components: {
        OrderCardBodyAdditionalInfo
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            additionalInfo: []
        }
    },
    created() {
        const {
            problems, slides, charts, pages
        } = this.order.project_information
        this.additionalInfo = [
            {
                value: pages,
                title: 'Page',
                icon: 'file-outline-icon'
            },
            {
                value: slides,
                title: 'Slide',
                icon: 'image-outline-icon'
            },
            {
                value: charts,
                title: 'Chart',
                icon: 'chart-box-outline-icon'
            },
            {
                value: problems,
                title: 'Problem',
                icon: 'alert-outline-icon'
            }
        ]
    }
}
</script>
